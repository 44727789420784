<template>
  <div class="wrapper backdrop"></div>
  <div class="wrapper message centered-content">
    <h1>{{ message }}</h1>
  </div>
</template>

<style scoped>
.backdrop {
  background-color: black;
  opacity: 0.5;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-border {
  height: 60px;
  width: 60px;
  z-index: 10000;
  opacity: 1;
  color: black;
}

.message {
  color: black;
  z-index: 10000;
  opacity: 1;
  font-weight: bold;
}
</style>

<script setup>
let runTimeConfig = useRuntimeConfig();

const props = defineProps({
  message: {
    type: String,
    default: "Loading...",
    required: false,
  },
});
let message = ref(props.message);
onMounted(() => {
  message.value = props.message;
});

watch(
  () => props.message,
  (value) => {
    message.value = value;
  }
);
</script>

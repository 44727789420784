<template>
  <nav
    class="navbar navbar-expand-lg sticky-top d-print-none bg-light"
    style="margin-bottom: 25px"
  >
    <button
      id="navbarBtn"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarToggler"
      aria-controls="navbarToggler"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"> </span>
    </button>
    <FadeInHeader class="ms-auto me-3" :title="runtimeConfig.public.title" />

    <!-- <div class="float-left-animation centered-content w-100">
      <h1>{{ runtimeConfig.public.title }}</h1>
    </div> -->

    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul
        id="dashboard"
        class="navbar-nav subBar ms-auto me-3 py-2"
        style="font-size: smaller"
      >
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'home' }"
            :to="{ name: 'home' }"
            @click="toggleNav()"
          >
            HOME
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'contact' }"
            :to="{ name: 'contact' }"
            @click="toggleNav()"
          >
            CONTACT
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'stands' }"
            :to="{ name: 'stands' }"
            @click="toggleNav()"
          >
            STANDS
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'shops' }"
            :to="{ name: 'shops' }"
            @click="toggleNav()"
          >
            SHOPS
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'hangers' }"
            :to="{ name: 'hangers' }"
            @click="toggleNav()"
          >
            HANGERS
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'house' }"
            :to="{ name: 'house' }"
            @click="toggleNav()"
          >
            HOUSE
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'services' }"
            :to="{ name: 'services' }"
            @click="toggleNav()"
          >
            SERVICES
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'cart' }"
            :to="{ name: 'cart' }"
            @click="toggleNav()"
          >
            CART
          </router-link>
        </li>
        <li class="nav-item" v-if="runtimeConfig.public.isAuthenticated">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'profile' }"
            :to="{ name: 'profile' }"
            @click="toggleNav()"
          >
            PROFILE
          </router-link>
        </li>
        <li class="nav-item" v-if="!runtimeConfig.public.isAuthenticated">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'register' }"
            :to="{ name: 'register' }"
            @click="toggleNav()"
          >
            REGISTER
          </router-link>
        </li>
        <li class="nav-item" v-if="!runtimeConfig.public.isAuthenticated">
          <router-link
            class="nav-link active"
            :class="{ selected: $route.name === 'register' }"
            :to="{ name: 'logIn' }"
            @click="toggleNav()"
          >
            LOGIN
          </router-link>
        </li>
        <li
          class="nav-item me-0 pe-0"
          v-else="!runtimeConfig.public.isAuthenticated"
        >
          <a
            class="dropdown-item me-0 pe-0"
            @click="
              showModal(
                'Logout',
                'Are you sure you want to log out?',
                'Yes',
                'No',
                () => userLogout()
              )
            "
          >
            LOG OFF
          </a>
        </li>
        <!--
        <li v-if="runtimeConfig.public.isAuthenticated === true" id="Admin" class="nav-item"><a class="nav-link active">USER</a>
          <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-left">
            <li><a :href="runtimeConfig.public.apiBase + '/admin/'" target="_blank" class="dropdown-item" @click="toggleNav()">Admin</a></li>
            <li><a class="dropdown-item" @click="showModal('Logout', 'Are you sure you want to log out?', 'Yes', 'No', () => userLogout())">Log off</a></li>
          </ul>
        </li>
        <li v-else-if="runtimeConfig.public.isAuthenticated == false" class="nav-item">
          <router-link class="nav-link active" :to="{name: 'logIn'}"  @click="toggleNav()">
          Log In</router-link>
        </li>
          -->
      </ul>
    </div>
  </nav>
</template>

<script setup>
import showModal from "~/composables/customModal.js";
const runtimeConfig = useRuntimeConfig();

function userLogout() {
  const payload = {
    api: "/auth/token/logout/",
    data: {},
  };
  post(payload);
  logout(false);
  toggleNav();
}

function toggleNav() {
  try {
    var nav = document.getElementById("navbarToggler");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  } catch {}
}
</script>

<style scoped type="text/css">
a:hover {
  cursor: pointer;
}

#title {
  background-color: rgb(162, 182, 205, 0.2);
}

.subBar {
  color: white;
}

a {
  margin: 0px 0px 0px 2px;
  padding: 0;
}

.nav-item {
  color: black;
  margin: 5px 0 5px 0;
  padding: 0;
}

.nav-link.selected {
  /* Styles for the "selected" class */
  font-weight: bold;
}

@media all {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
    color: grey;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu-left {
    position: absolute;
    right: 0 !important;
  }

  @media (max-width: 992px) {
    /* 992px is the breakpoint for "navbar-expand-lg" in Bootstrap */
    .navbar-expand-lg .dropdown-menu-left {
      right: auto !important;
      left: 0 !important;
    }
    #subBar {
      padding-top: 5px;
      margin-top: 10px;
      /* background-color: inherit; */
    }
  }
}
</style>
